import { GetDataQuery } from '@gql_codegen/retail-types';
import { TRACKING_SECTION, TRACKING_SECTION_CATEGORY } from '@src/constants';
import { FormNamesUnion } from '@src/layout';
import { EnumLike } from '@src/types/utilTypes';
import { restrictFloatInput } from '@utils/validators';
import { InputNumberProps, InputProps } from 'antd';

export const SWPathStart = `adMgmt.autoheroAndWkda.secondaryWheels.data`;

export type TableColOptionsKeys =
  | 'frontLeft'
  | 'frontRight'
  | 'rearLeft'
  | 'rearRight';
type TableColOptions = { key: TableColOptionsKeys; title: string };
export const tableColOptions = [
  { key: 'frontLeft', title: 'Front Left' },
  { key: 'frontRight', title: 'Front Right' },
  { key: 'rearLeft', title: 'Back Left' },
  { key: 'rearRight', title: 'Back Right' },
] as const satisfies readonly TableColOptions[];

type GetSWPathRestPart<T> = T extends `${typeof SWPathStart}.${infer R}`
  ? R
  : never;
type GetSWTopDomainNames<T> = T extends `${infer R}.${string}` ? R : never;

type SWTopDomainNames = GetSWTopDomainNames<GetSWPathRestPart<FormNamesUnion>>;
type SecondaryWheelsNames = Exclude<
  SWTopDomainNames,
  | 'ad_secondary_wheels_damages'
  | 'ad_secondary_wheels'
  | 'tiresDetails'
  | 'rimsDetails'
>;

type SecondaryWheelsFieldsPrefix = `secondaryWheels`;
type SecondaryWheelsFieldsWithPrefix<T extends string = SecondaryWheelsNames> =
  `${SecondaryWheelsFieldsPrefix}${Capitalize<T>}`;

type TableRowOptions = EnumLike<SecondaryWheelsFieldsWithPrefix>;

export const tableRowOptions = {
  secondaryWheelsSeasonType: 'secondaryWheelsSeasonType',
  secondaryWheelsTreadDepth: 'secondaryWheelsTreadDepth',
  secondaryWheelsRadius: 'secondaryWheelsRadius',
  secondaryWheelsMaterial: 'secondaryWheelsMaterial',
  secondaryWheelsManufacturer: 'secondaryWheelsManufacturer',
  secondaryWheelsDot: 'secondaryWheelsDot',
  secondaryWheelsWheelWidth: 'secondaryWheelsWheelWidth',
  secondaryWheelsWheelHeight: 'secondaryWheelsWheelHeight',
  secondaryWheelsLoadIndex: 'secondaryWheelsLoadIndex',
  secondaryWheelsSpeedIndex: 'secondaryWheelsSpeedIndex',
} as const satisfies TableRowOptions;

type GlobalDictionaryKeys =
  keyof GetDataQuery['adMgmt']['dictionaries']['global'];

type SecondaryWheelsItemBase = {
  title: string;
  type: SecondaryWheelsFieldsWithPrefix;
  dictionary: GlobalDictionaryKeys | undefined;
  tracking: {
    fieldId: string;
    eventCategory: string;
    section: typeof TRACKING_SECTION.SECONDARY;
    sectionCategory: typeof TRACKING_SECTION_CATEGORY.AH;
  };
  inputProps?: InputProps | InputNumberProps;
};

type UniqueRecordByType = {
  [SWName in SecondaryWheelsNames as SecondaryWheelsFieldsWithPrefix<SWName>]: SecondaryWheelsItemBase & {
    type: SecondaryWheelsFieldsWithPrefix<SWName>;
  } & {
    [Key in TableColOptionsKeys]: GetSWPathRestPart<
      Extract<FormNamesUnion, `${string}.${SWName}.${Key}`>
    >;
  };
};

type WheelsInfoRecord = UniqueRecordByType;
export type WheelsInfoRecordItems = WheelsInfoRecord[keyof WheelsInfoRecord];

//if you someone can make it array with uniqueness by type field without forcing TS type system to create each possible permutation, then feel free to change it so...
export const wheelsInfoRecord = {
  secondaryWheelsSeasonType: {
    title: 'Season',
    type: 'secondaryWheelsSeasonType',
    dictionary: 'secondaryWheelsSeasonType',
    rearLeft: 'seasonType.rearLeft',
    rearRight: 'seasonType.rearRight',
    frontLeft: 'seasonType.frontLeft',
    frontRight: 'seasonType.frontRight',
    tracking: {
      fieldId: 'Secondary_Wheels_Season',
      eventCategory: 'modify',
      section: TRACKING_SECTION.SECONDARY,
      sectionCategory: TRACKING_SECTION_CATEGORY.AH,
    },
  },
  secondaryWheelsTreadDepth: {
    title: 'Profile depth (mm)',
    type: 'secondaryWheelsTreadDepth',
    dictionary: 'secondaryWheelsTreadDepth',
    rearLeft: 'treadDepth.rearLeft',
    rearRight: 'treadDepth.rearRight',
    frontLeft: 'treadDepth.frontLeft',
    frontRight: 'treadDepth.frontRight',
    tracking: {
      eventCategory: 'modify',
      fieldId: 'Secondary_Wheels_Profile depth',
      section: TRACKING_SECTION.SECONDARY,
      sectionCategory: TRACKING_SECTION_CATEGORY.AH,
    },
  },
  secondaryWheelsRadius: {
    title: 'Wheel radius (inches)',
    type: 'secondaryWheelsRadius',
    dictionary: 'secondaryWheelsRadius',
    rearLeft: 'radius.rearLeft',
    rearRight: 'radius.rearRight',
    frontLeft: 'radius.frontLeft',
    frontRight: 'radius.frontRight',
    tracking: {
      eventCategory: 'modify',
      fieldId: 'Secondary_Wheels_Wheel radius',
      section: TRACKING_SECTION.SECONDARY,
      sectionCategory: TRACKING_SECTION_CATEGORY.AH,
    },
  },
  secondaryWheelsMaterial: {
    title: 'Rim type',
    type: 'secondaryWheelsMaterial',
    dictionary: 'secondaryWheelsMaterial',
    rearLeft: 'material.rearLeft',
    rearRight: 'material.rearRight',
    frontLeft: 'material.frontLeft',
    frontRight: 'material.frontRight',
    tracking: {
      eventCategory: 'modify',
      fieldId: 'Secondary_Wheels_Rim type',
      section: TRACKING_SECTION.SECONDARY,
      sectionCategory: TRACKING_SECTION_CATEGORY.AH,
    },
  },
  secondaryWheelsManufacturer: {
    title: 'Manufacturer',
    type: 'secondaryWheelsManufacturer',
    dictionary: undefined,
    rearLeft: 'manufacturer.rearLeft',
    rearRight: 'manufacturer.rearRight',
    frontLeft: 'manufacturer.frontLeft',
    frontRight: 'manufacturer.frontRight',
    tracking: {
      eventCategory: 'modify',
      fieldId: 'Secondary_Wheels_Manufacturer',
      section: TRACKING_SECTION.SECONDARY,
      sectionCategory: TRACKING_SECTION_CATEGORY.AH,
    },
    inputProps: { maxLength: 24, showCount: true },
  },
  secondaryWheelsDot: {
    title: 'DOT (WWYY)',
    type: 'secondaryWheelsDot',
    dictionary: undefined,
    rearLeft: 'dot.rearLeft',
    rearRight: 'dot.rearRight',
    frontLeft: 'dot.frontLeft',
    frontRight: 'dot.frontRight',
    tracking: {
      eventCategory: 'modify',
      fieldId: 'Secondary_Wheels_DOT',
      section: TRACKING_SECTION.SECONDARY,
      sectionCategory: TRACKING_SECTION_CATEGORY.AH,
    },
    inputProps: {
      maxLength: 4,
      showCount: true,
      onKeyDown: restrictFloatInput,
    },
  },
  secondaryWheelsWheelWidth: {
    title: 'Wheel Width (mm)',
    type: 'secondaryWheelsWheelWidth',
    dictionary: undefined,
    rearLeft: 'wheelWidth.rearLeft',
    rearRight: 'wheelWidth.rearRight',
    frontLeft: 'wheelWidth.frontLeft',
    frontRight: 'wheelWidth.frontRight',
    tracking: {
      eventCategory: 'modify',
      fieldId: 'Secondary_Wheels_Wheel_Width',
      section: TRACKING_SECTION.SECONDARY,
      sectionCategory: TRACKING_SECTION_CATEGORY.AH,
    },
    inputProps: { max: 9999, min: 0, onKeyDown: restrictFloatInput },
  },
  secondaryWheelsWheelHeight: {
    title: 'Wheel Height',
    type: 'secondaryWheelsWheelHeight',
    dictionary: undefined,
    rearLeft: 'wheelHeight.rearLeft',
    rearRight: 'wheelHeight.rearRight',
    frontLeft: 'wheelHeight.frontLeft',
    frontRight: 'wheelHeight.frontRight',
    tracking: {
      eventCategory: 'modify',
      fieldId: 'Secondary_Wheels_Wheel_Height',
      section: TRACKING_SECTION.SECONDARY,
      sectionCategory: TRACKING_SECTION_CATEGORY.AH,
    },
    inputProps: { max: 9999, min: 0, onKeyDown: restrictFloatInput },
  },
  secondaryWheelsLoadIndex: {
    title: 'Load Index',
    type: 'secondaryWheelsLoadIndex',
    dictionary: undefined,
    rearLeft: 'loadIndex.rearLeft',
    rearRight: 'loadIndex.rearRight',
    frontLeft: 'loadIndex.frontLeft',
    frontRight: 'loadIndex.frontRight',
    tracking: {
      eventCategory: 'modify',
      fieldId: 'Secondary_Wheels_Load_Index',
      section: TRACKING_SECTION.SECONDARY,
      sectionCategory: TRACKING_SECTION_CATEGORY.AH,
    },
    inputProps: { max: 9999, min: 0, onKeyDown: restrictFloatInput },
  },
  secondaryWheelsSpeedIndex: {
    title: 'Speed Index',
    type: 'secondaryWheelsSpeedIndex',
    dictionary: undefined,
    rearLeft: 'speedIndex.rearLeft',
    rearRight: 'speedIndex.rearRight',
    frontLeft: 'speedIndex.frontLeft',
    frontRight: 'speedIndex.frontRight',
    tracking: {
      eventCategory: 'modify',
      fieldId: 'Secondary_Wheels_Load_Index',
      section: TRACKING_SECTION.SECONDARY,
      sectionCategory: TRACKING_SECTION_CATEGORY.AH,
    },
    inputProps: { maxLength: 24, showCount: true },
  },
  //TODO: may be reverted after 01.04.2024 otherwise delete it
  // secondaryWheelsTiresDetails: {
  //   title: 'Wheel Damage Types',
  //   type: 'secondaryWheelsTiresDetails',
  //   dictionary: 'secondaryWheelsTiresDetails',
  //   rearLeft: 'tiresDetails.rearLeft',
  //   rearRight: 'tiresDetails.rearRight',
  //   frontLeft: 'tiresDetails.frontLeft',
  //   frontRight: 'tiresDetails.frontRight',
  //   tracking: {
  //     eventCategory: 'modify',
  //     fieldId: 'Secondary_Wheels_Wheel Damage Types',
  //     section: TRACKING_SECTION.SECONDARY,
  //     sectionCategory: TRACKING_SECTION_CATEGORY.AH,
  //   },
  // },
  // secondaryWheelsRimsDetails: {
  //   title: 'Rim Damage Types',
  //   type: 'secondaryWheelsRimsDetails',
  //   dictionary: 'secondaryWheelsRimsDetails',
  //   rearLeft: 'rimsDetails.rearLeft',
  //   rearRight: 'rimsDetails.rearRight',
  //   frontLeft: 'rimsDetails.frontLeft',
  //   frontRight: 'rimsDetails.frontRight',
  //   tracking: {
  //     eventCategory: 'modify',
  //     fieldId: 'Secondary_Wheels_Rim Damage Types',
  //     section: TRACKING_SECTION.SECONDARY,
  //     sectionCategory: TRACKING_SECTION_CATEGORY.AH,
  //   },
  // },
} as const satisfies Readonly<WheelsInfoRecord>;
export const wheelsInfo = Object.values(wheelsInfoRecord);
